@import "_variables";
@import "_helpers";
@import "_overrides";
// @import "spacing";

body {
  margin: 0;
  font-family: $font-family, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// Screens
@import "../screens/Root/Root";
@import "../screens/AppointmentsScreen/AppointmentsScreen";
@import "../screens/AppointmentsScreenSingle/AppointmentsScreenSingle";
@import "../screens/AppointmentsDoctorsListScreen/AppointmentsDoctorsListScreen";
@import "../screens/AppointmentsDoctorsSingleScreen/AppointmentsDoctorsSingleScreen";
@import "../screens/HomeScreen/HomeScreen";
@import "../screens/LoginScreen/Login";
@import "../screens/ProfileScreen/ProfileScreen";
@import "../screens/SymptomsCheckerScreen/SymptomsCheckerScreen";
@import "../screens/VisitsScreen/VisitsScreen";
@import "../screens/VisitsSessionScreen/VisitsSessionScreen";
@import "../screens/VisitsScreenSingle/VisitsScreenSingle";
@import "../screens/NotificationsScreen/NotificationsScreen.scss";

// Components
@import "../components/LoginButton/LoginButton";
@import "../components/MainMenuWrapper/MainMenuWrapper";
@import "../components/MainMenu/MainMenu";
@import "../components/FooterContainer/FooterContainer";
@import "../components/UserPopUp/UserPopUp";
@import "../components/NotificationsList/NotificationsList";
@import "../components/CallToAction/CallToAction";
@import "../components/SymptomsCheckerMessage/SymptomsCheckerMessage";
@import "../components/QuickReplies/QuickReplies";
@import "../components/modals/OutgoingCallModal/OutgoingCallModal";
@import "../components/InfoCard/InfoCard";
@import "../components/InfoCardHeader/InfoCardHeader";
@import "../components/ConsultationInfo/ConsultationInfo";
@import "../components/filesManager/FileThumb";
@import "../components/AttachmentsGallery/AttachmentsGallery";
@import "../components/NavDatePicker/NavDatePicker";
@import "../components/video_call/MiniVideoCallPlayer/MiniVideoCallPlayer";
@import "../components/video_call/ParticipantInfo/ParticipantInfo";
@import "../components/MedicalProfile/MPStylesheet";
@import "../components/ActiveAppointmentsBanner/ActiveAppointmentsBanner";
