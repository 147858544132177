.appointments-single {
  &__alert-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    background-color: $banner-color;
  }

  &__alert-banner span {
    margin-left: 10px;
    font-family: $font-family, sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    color: $black;
  }

  &__section {
    margin-bottom: 2em;
  }

  &__files {
    margin-top: 1em;
  }
}
