.user-popup {
  max-width: 100%;
  text-align: left;
  &:hover {
    cursor: default;
  }
  &__body {
    padding: 20px 0 0;
    .ant-typography {
      padding: 0;
      margin: 0;
    }
  }
  &__footer {
    border-top: 1px solid #edf4fa;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 0;
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .user-popup {
    min-width: 375px;
    text-align: center;
  }
}
