.footer-container {
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.2);
  &__links-wrapper {
    padding-bottom: 25px;
  }
  .ant-typography,
  .ant-btn.ant-btn-link {
    font-size: 13px;
  }
  .ant-btn.ant-btn-link {
    font-weight: normal;
    span {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &__social-wrapper {
    margin-bottom: 15px;
    .social-icon {
      margin-right: 5px;
      width: 38px !important;
      height: 38px !important;
    }
  }

  &__logo {
    width: 234px;
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .footer-container {
    &__links-wrapper {
      padding-bottom: 0;
    }
    &__logo-wrapper {
      text-align: right;
    }
  }
}
