.header-bar {
  background: $white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);

  &__wrapper {
    height: 100%;
    background: $white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    height: 70px;
    display: flex;
    align-items: center;
  }
  &__logo {
    max-width: 185px;

    img {
      width: 185px;
    }
  }

  &__drawer-trigger {
    .anticon {
      font-size: 22px;
    }
  }
}
.app-version {
  font-size: 14px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  color: $text-muted;
}
@media (max-width: $grid-breakpoints-lg) {
  .header-bar {
    &__logo {
      margin: 10px 0;
    }
  }
  .ant-layout-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}
