.NavDatePicker {
  width: 100%;
  background: $grey-light;
  display: flex;
  justify-content: space-between;
  
  &__DatePicker {
    background-color: transparent;
    border: 0px;
  }
}