.profile-header {
  justify-content: space-between;
  align-items: center;
}

.profile-title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}

.profile-add-new {
  color: #c21c1d;
  font-family: Manrope;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.profile-sidebar-title {
  color: #484848;
  font-family: Manrope;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.profile-sidebar-title:focus {
  background: transparent;
}

.profile-sidebar-icon {
  width: 40px;
  height: 40px;
  padding: 8px;
  margin-right: 16px;
  background-color: rgba(194, 28, 29, 0.03);
}

.drop-shadow {
  box-shadow: 0 4px 32px -8px rgba(194, 28, 29, 0.08),
    0 8px 32px -4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff;
}

.profile-section {
  padding-left: 222px;
}

.profile-save-button {
  display: flex;
  justify-content: center;
}

.profile-modal .ant-modal-title {
  font-weight: bold;
  text-align: center;
  color: #484848;
}

.profile-sidebar-background {
  background-color: #f5f5f5;
}

.profile-screen-listItem {
  cursor: pointer;
}

.profile-footer {
  margin-bottom: 34px;
}
