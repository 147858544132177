.appointments-doctors-list {
  &__selected-sp {
    cursor: pointer;
    background-color: $grey-light;
  }

  &__selected-sp span {
    color: $red-dark;
  }

  &__date-picker-col {
    padding: 0 12px 0 12px;
  }

  &__slots-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 25px 25px 25px;
  }

  @media (max-width: 990px) {
    &__specialities-col {
      max-height: 400px;
      overflow: scroll;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}