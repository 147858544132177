.home-screen {
  h5.ant-typography {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 25px;
    line-height: 115%;
  }
  &__hero-section {
    padding: 25px 15px;
    background-image: url(../../assets/images/home/header.jpg);
    background-position: center center;
    background-size: cover;

    .ant-typography {
      font-size: 20px;
    }

    

    .ant-btn {
      min-width: 100%;
      font-size: 14px;
      vertical-align: middle;
      &.custom-secondary {
        background-color: $red-light;
        border: 0 none;
        color: #fff;
        &:hover {
          background-color: rgba(227, 76, 60, 0.75);
        }
      }

      &:first-child {
        margin-right: 5px;
      }

      .anticon {
        font-size: 32px;
      }
      & > span {
        vertical-align: middle;
      }
    }

    .mobile-btn {
      font-size: 18px;
    }

    .mobile-btn > .anticon {
      font-size: 18px;
    }

    @media (min-width: $grid-breakpoints-md) {
      padding-top: 100px;
    }
  }
  &__cta-area {
    margin-top: 80px;
  }

  &__nutritionist-banner {
    background: #c0d4c2;
    padding: 50px 0;

    h5.ant-typography {
      color: #337353;
    }
    .nutritionist-btn.ant-btn {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      background: $white;
      border: 0 none;
      max-width: 400px;
      width: 100%;
      height: auto;
      margin: 0 auto;
      color: #337353;

      &:hover {
        background: rgba(250, 250, 250, 0.9);
      }
      img {
        margin-right: 18%;
      }
    }
  }
  &__stores {
    padding: 50px 0;
    h5.ant-typography {
      color: #6f7676;
      margin-bottom: 40px;
    }
  }
}

@media (min-width: $grid-breakpoints-xs) {
  .home-screen {
    &__hero-section {
      .ant-btn {
        min-width: 375 px;
        font-size: 18px;
      }
    }
  }
}

.imagesParent {
  position: relative;
  top: 0;
  left: 0;
}

.imageOne {
  position: relative;
  top: 0;
  left: 0;
}

.imageTwo {
  position: absolute;
  top: 388px;
  left: 66px;
}