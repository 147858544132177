.files-gallery {
  display: flex;
  margin-top: 15px;
  .preview-pdf {
    margin-right: 10px;
    border-radius: 5px;
    width: 60px;
    background: $grey-dark;

    > a {
      font-size: 20px;
      color: $text-muted;
      display: inline-flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-image {
    margin-right: 10px;
    display: inline-block;
    .ant-image-mask {
      .ant-image-mask-info {
        font-size: 11px;
      }
    }
    .preview-img {
      border-radius: 5px;
    }
  }
}
