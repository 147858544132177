.symptoms-checker {
  &__main-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;

    height: calc(100vh - var(--sumSCHeight));
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0px; /* remove scrollbar space */
      background: transparent; /* optional: just make scrollbar invisible */
    }
  }

  &__action-btn {
    margin: 25px 15px 8px;
  }
  &__restart-btn {
    margin-bottom: 15px;
  }
  &__input-bar-container {
    background-color: #ffffff;
    padding: 10px 15px 0;
    margin-bottom: 15px;
  }

  &__input-bar-container {
    .ant-btn {
      &.ant-btn-icon-only {
        width: 25px;
        height: 25px;
        min-width: 25px;
        line-height: 25px;
        padding: 0;
        .anticon {
          font-size: 15px;
        }
      }
    }
  }
}
