.site-layout-background {
  padding: 0;
  min-height: 280px;
}

.site-loading {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}