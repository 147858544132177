.quick-replies {
  display: flex;
  flex-direction: "row";
  padding: 12px 16px;

  &__btn {
    width: 30%;
    margin-right: 12px;

    &.last-btn {
      margin-right: 0;
    }
  }
}
