.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.clear {
  clear: both;
}
.hidden {
  display: none;
}
.right {
  float: right;
}
.left {
  float: left;
}
.full-height {
  min-height: calc(100vh - 50px);
  // min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}
.small {
  font-size: 0.82rem;
}

/* Flex direction */
.fd-r {
  flex-direction: row;
} /* Default */
.fd-rr {
  flex-direction: row-reverse;
}
.fd-c {
  flex-direction: column;
}
.fd-cr {
  flex-direction: column-reverse;
}

/* Flex wrap */
.fw-n {
  flex-wrap: nowrap;
} /* Default */
.fw-w {
  flex-wrap: wrap;
}
.fw-wr {
  flex-wrap: wrap-reverse;
}

/* Justify content */
.jc-fe {
  justify-content: flex-end;
}
.jc-fs {
  justify-content: flex-start;
} /* Default */
.jc-c {
  justify-content: center;
}
.jc-sa {
  justify-content: space-around;
}
.jc-sb {
  justify-content: space-between;
}

/* Align items */
.ai-fs {
  align-items: flex-start;
}
.ai-c {
  align-items: center;
}
.ai-fe {
  align-items: flex-end;
}
.ai-s {
  align-items: stretch;
} /* Default */
.ai-b {
  align-items: baseline;
}

/* Align self */
.as-a {
  align-self: auto;
} /* Default */
.as-fs {
  align-self: flex-start;
}
.as-c {
  align-self: center;
}
.as-fe {
  align-self: flex-end;
}
.as-s {
  align-self: stretch;
}
.as-b {
  align-self: baseline;
}

// Generate text-right / left based on breakpoints ex text-md-right / text-lg-left
$breakpoints: (
  ($grid-breakpoints-xs, "xs"),
  ($grid-breakpoints-sm, "sm"),
  ($grid-breakpoints-md, "md"),
  ($grid-breakpoints-lg, "lg"),
  ($grid-breakpoints-xl, "xl"),
  ($grid-breakpoints-xxl, "xxl")
);

@each $breakpoint in $breakpoints {
  $breakpointKey: nth($breakpoint, 1);
  $breakpointValue: nth($breakpoint, 2);
  @media (min-width: #{$breakpointKey}) {
    .text-#{$breakpointValue}-right {
      text-align: right;
    }
    .text-#{$breakpointValue}-left {
      text-align: left;
    }
  }
}

// Generate spacing classes like mt-2 (margin-top: 2rem) or pb-5(padding-bottom: 5rem)
$sizeUnit: rem;
$marginKey: "m";
$paddingKey: "p";
$separator: "-";
$sizes: (("0", 0), ("1", 0.25), ("2", 0.5), ("3", 1), ("4", 1.5), ("5", 3));
$positions: (("t", "top"), ("r", "right"), ("b", "bottom"), ("l", "left"));

@function sizeValue($key, $value) {
  @return if($key == "none", 0, $value + $sizeUnit);
}

@each $size in $sizes {
  $sizeKey: nth($size, 1);
  $sizeValue: nth($size, 2);
  .#{$marginKey}#{$separator}#{$sizeKey} {
    margin: sizeValue($sizeKey, $sizeValue);
  }
  .#{$paddingKey}#{$separator}#{$sizeKey} {
    padding: sizeValue($sizeKey, $sizeValue);
  }
  @each $position in $positions {
    $posKey: nth($position, 1);
    $posValue: nth($position, 2);
    .#{$marginKey}#{$posKey}#{$separator}#{$sizeKey} {
      margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
    }
    .#{$paddingKey}#{$posKey}#{$separator}#{$sizeKey} {
      padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
    }
  }
}

// @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }
