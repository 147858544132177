.info-card-header {
  display: flex;
  align-items: center;
  &__body {
    flex: 1;
    overflow: hidden;
    margin-left: 15px;
    h4.ant-typography {
      font-size: 18px;
      margin-bottom: 5px;
      line-height: 120%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
