$font-family: "Manrope", sans-serif;

$font-size-base: 18px;
$font-size-sm: 14px;

$white: #ffffff;
$black: #000000;

$background-color-light: #e5e5e5;
$primary-color: #c21c1d;
$primary-color-10: rgba(197, 40, 28, 0.1);
$secondary-color: #a2acb4;
$text-black: #4c4c4c;
$text-muted: #484848;

$grey-light: #f5f5f5;
$grey-dark: #f2f2f2;

$red-light: #e34c3c;
$red-dark: #8e1230;
$orange-color: #E9573D;

$success-color: #1ec760;
$error-color: #ff4e32;
$warning-color: #f9b700;
$info-color: #00a5ff;

$green-light: #1ec760;
$green-light-2: #24d268;
$box-primary-shadow-color: rgba(41, 64, 77, 0.2);

$box-top-radius: 40px 40px 0 0;
$box-bottom-radius: 0 0 40px 40px;

$banner-color: #ffe59e;

//
// Grid
//
$grid-breakpoints-xs: 480px;
$grid-breakpoints-sm: 576px;
$grid-breakpoints-md: 768px;
$grid-breakpoints-lg: 992px;
$grid-breakpoints-xl: 1200px;
$grid-breakpoints-xxl: 1600px;
