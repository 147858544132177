.main-menu {
  height: 100%;
  background: transparent;
  &.ant-menu-horizontal {
    border-bottom: 0 none;
  }
  &.ant-menu-inline {
    border-right: 0 none;
  }

  .main-menu__icon-link {
    &.ant-menu-item,
    .ant-menu-submenu-title {
      .anticon {
        font-size: 20px;
        margin-right: 0px;
      }
    }
  }
  .ant-menu-item {
    .anticon {
      font-size: 18px;
    }
  }

  &__icon-link-copy {
    margin-left: 8px;
  }

  &__with-badge {
    .ant-badge {
      color: inherit;
    }
    .ant-typography {
      color: inherit;
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .main-menu {
    .main-menu__icon-link {
      font-size: 21px;
      margin: 0 10px !important;
      padding-bottom: 8px !important;
    }
    &__menu-item {
      &.ant-menu-item {
        padding: 8px 10px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
        border-radius: 4px;
      }
    }
  }
}

@media (max-width: $grid-breakpoints-lg) {
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 12px !important;
  }
}
