.file-thumb {

  &__container {
    position: relative;

    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    background-color: '#818D99';
    border-radius: 8;
    cursor: pointer;
  }

  &__close-btn span {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close-btn svg {
    font-size: 12px;
  }
}

