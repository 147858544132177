// Hide Arrows from input with type='number'
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.ant-menu-vertical > .ant-menu-item {
  height: auto;
  line-height: auto;
}

.ant-row,
.ant-col {
  width: 100%;
}

// Heading
// ---------
h5.ant-typography {
  color: $text-black;
}

// Main Menu
// ---------
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border: 0 none !important;
}
.ant-menu-item {
  &-selected {
    font-weight: bold;
    background: $grey-dark !important;
  }
  &-selected,
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: $red-light;
  }
}

// Button
// ---------
.ant-btn {
  border-color: $primary-color;
  border-width: 1px;
  border-style: solid;
  &.ant-btn-link {
    border: 0 none;
  }
  &.ant-btn-primary,
  &.ant-btn-tertiary {
    border: 0 none;
  }
  &.ant-btn-tertiary {
    background-color: $green-light;
    color: #ffffff;

    &:hover {
      background-color: $green-light-2;
    }
  }
}

// Tags
// ------
.ant-tag {
  border: 0 none;
  color: #ffffff;
  padding: 3px 15px;
  text-transform: uppercase;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 125%;
  &-warning {
    background: $warning-color;
  }
  &-success {
    background: $success-color;
  }
  &-error {
    background: $error-color;
  }
  &-info {
    background: $info-color;
  }
}

// Header
// ------
.ant-page-header {
  padding-left: 0;
  padding-right: 0;
  .ant-page-header-content {
    .ant-btn.ant-btn-link {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
