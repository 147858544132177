.message {
  margin-top: 5px;
  border-radius: 12px;
  padding: 12px 16px;

  &.incoming-message {
    background-color: #edf4fa;
    margin-left: 16px;
    margin-right: 28px;
    border-top-left-radius: 0;
    align-self: flex-start;
  }
  &.outgoing-message {
    background-color: #e9573d;
    margin-left: 28px;
    margin-right: 16px;
    border-bottom-right-radius: 0;
    align-self: flex-end;
    span.ant-typography {
      color: #ffffff;
    }
  }
  .probability {
    margin-bottom: 6px;
  }
  .condition-text {
    font-weight: bold;
  }
}
