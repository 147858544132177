.notifications {
  width: 375px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;

  .ant-list-item {
    justify-content: flex-start;

    &.not-read {
      background: $primary-color-10;
    }
  }
  &__item {
    &:hover {
      cursor: pointer;
    }
  }
  &__item-body {
    margin-left: 15px;
    flex: 1;

    h5.ant-typography {
      font-family: $font-family, sans-serif;
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 0;
      line-height: 100%;
      margin-bottom: 5px;
    }
    span.ant-typography {
      display: block;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;

      &.time {
        font-size: 12px;
        font-weight: 800;
        color: #818d99;
      }
    }
  }
}
