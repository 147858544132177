.fullWidth {
  width: 100%;
}

.profile-modal .ant-modal-body {
  max-height: calc(100vh - 250px);
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}
