.info-card {
  width: 100%;
  margin-bottom: 25px;
  background: $grey-light;
  > a {
    display: block;
    padding: 25px;
  }
}

.info-card-selected {
  background-color: rgba(197, 40, 28, 0.3);
}
