.MiniVideoCallPlayer {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;

  &__tracks-container {
    position: relative;
    height: 163px;
    width: 290px;
    background: #000;
    border-radius: 4px;
  }

  &__controls {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  :hover > .MiniVideoCallPlayer__controls {
    visibility: visible;
  }

  .participant_info-info_container {
    z-index: auto;
  }
}
