.OutgoingCallModal__container {
  max-width: 451px;
  margin: auto;
}

.OutgoingCallModal__container .content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.OutgoingCallModal__container .outter-circle {
  box-sizing: border-box;
  height: 277px;
  width: 277px;
  border-radius: 138px;
  border: 1px solid rgba(111, 31, 31, 0.13);
  background-color: rgba(255, 255, 255, 0.63);
  display: flex;
  align-items: center;
  justify-content: center;
}

.OutgoingCallModal__container .middle-circle {
  box-sizing: border-box;
  height: 234px;
  width: 234px;
  border-radius: 117px;
  border: 1px solid #FFFFFF;
  background-color: rgba(51, 10, 7, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}

.OutgoingCallModal__container .inner-circle {
  box-sizing: border-box;
  height: 186px;
  width: 186px;
  border-radius: 93px;
  border: 1px solid #FF9797;
  background-color: rgba(115, 100, 99, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
}

.OutgoingCallModal__container .inner-circle img {
  height: 113px;
}