.ActiveAppointmentsBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: $banner-color;

  .ant-typography {
    margin-left: 10px;
    font-family: $font-family, sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    color: $black;
  }

  a:hover {
    text-decoration: underline;
    color: $red-dark;
  }
  a > .ant-typography {
    color: $red-dark;
  }


  button > span {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button > svg {
    font-size: 12px;
  }
  .ant-btn-primary {
    color: $black;
  }
}
