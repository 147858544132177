.appointments-doctors-single {
  margin-top: 25px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    width: 100%;
    background: $grey-light;
  }

  &__avatar {
    margin-bottom: 14px;
  }

  &__section-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 15px;
  }

  &__section-title {
    font-weight: bold;
    text-align: center;
  }

  &__section-divider {
    height: 2px;
    width: 56px;
    background-color: $orange-color;
    margin-top: 8px;
  }

  &__slots-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 25px 25px 25px;
  }

  &__slots-loader {
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 25px;
  }

  &__section-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 150px;
  }

  &__section-button .ant-btn-primary {
    background-color: $success-color;
    width: 356px;
  }
}
