.call-to-action {
  &.ant-btn {
    max-width: 190px;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    background: rgba(255, 255, 255, 0.9);
    min-width: auto;
    font-size: 14px;
    border: 0 none;
    white-space: normal;
    > span {
      display: block;
      line-height: 130%;
    }
    > .anticon + span,
    > span + .anticon {
      margin-left: 0;
    }
    &:hover {
      background: rgba(250, 250, 250, 0.9);
    }
  }
}
