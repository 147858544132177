.consultation-info {
  &__info {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
  }
  &__body-info {
    display: flex;
  }
  span.ant-typography {
    display: inline-block;
    margin-left: 15px;
    font-size: 15px;
    line-height: 110%;

    &.appointment-info {
      display: block;
      font-size: 13px;
      font-weight: bold;
      color: $red-dark;
      padding-bottom: 8px;
    }
  }
  &__appointments-info {
    .ant-divider {
      margin: 10px 0;
    }
    .ant-btn {
      margin-top: 8px;
    }
  }
}
