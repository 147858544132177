.VisitsSessionScreen {
  $headerHeight: 70px;
  $screenHeight: calc(100vh - #{$headerHeight});
  $subscriberHeightSmall: 350px;

  &__video {
    position: relative;
    height: $screenHeight;
    // background-color: #000000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__waiting-room {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
